//-- Effects

.shadow-1 {
  box-shadow: 10px 10px 0 0 $brand-second;
}

.shadow-2 {
  box-shadow: 1rem 2rem 4rem -2rem rgba(black, 0.9);
}

// see : https://codepen.io/iamvdo/pen/lGvCo
.drop-shadow {
  filter: drop-shadow(16px 16px 20px rgba(black, 0.3));
}
