//-- Positions

.relative {
  position: relative;
}

.sticky-top {
  position: sticky;
  top: 5rem;
}

.lg-sticky-top-6 {

  @media(min-width: $screen-lg-min) {
    position: sticky;
    top: 2rem;
  }
}
