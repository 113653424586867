//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 50%) !default; 	// #555
$gray-light:            #eef0f1 !default;   // #999
$gray-lighter:           lighten($gray-base, 96%) !default; 	// #eee
$gray-500:               #e6e6e6;
$gray-400:               #ddd;

$brand-primary:         #6a6a6a !default;
$brand-second:          #e3b933 !default;
$brand-success:         #e3b933 !default;
$brand-info:            #41aae1 !default;
$brand-warning:         #f26522 !default;
$brand-danger:          #cf2d13 !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #f5f5f5 !default;
//** Global text color on `<body>`.
$text-color:            $gray !default;
//** Global titles color.
$headings-color:        $gray-darker !default;

//** Global textual link color.
$link-color:            $brand-second !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 20%) !default;
//** Link hover decoration.
$link-hover-decoration: none !default;
