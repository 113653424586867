.wrapper {

  @media(min-width: $screen-lg-min) {
    display: flex;
  }
}

/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 50vh;
}

/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-8;
	@extend .col-lg-9;
	padding: 2rem;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-4;
	@extend .col-lg-3;
}

/* ==========================================================================
	Bouton lien _config et passe
	========================================================================== */

	.links-config{
		position: fixed;
		bottom: 20px;
		left: 20px;
		z-index: 99999;
		margin-bottom: 0;
		padding: 9px 15px;

		background-color: rgba(#333, .3);

		.close{
			top : 0;
			right: .2rem;
			font-size: 18px;
		}
	}
