//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                400 !default;

$btn-default-color:              $gray-dark !default;
$btn-default-bg:                 white !default;
$btn-default-border:             $btn-default-bg !default;

$btn-primary-color:              white !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             $btn-primary-bg !default;

$btn-second-color:              white !default;
$btn-second-bg:                 $brand-second !default;
$btn-second-border:             $btn-second-bg !default;

$btn-white-color:              $gray-darker !default;
$btn-white-bg:                 $gray-lighter !default;
$btn-white-border:             $gray-lighter !default;

$btn-success-color:              white !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             $btn-success-bg !default;

$btn-info-color:                 white !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                $btn-info-bg !default;

$btn-warning-color:              white !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $btn-warning-bg !default;

$btn-danger-color:               white !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              $btn-danger-bg !default;

$btn-link-disabled-color:        $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;
