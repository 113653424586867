/* ==========================================================================
  Fiche rsum
  ========================================================================== */

.fiche-resum {
  @extend .center-block;
  position: relative;
  overflow: hidden;
  border: none;
  // max-width: 27rem;
  // margin-bottom: 0;
}

.fiche-resum_label {
  position: absolute;
  z-index: 1;
  top: .5rem;
  right: .5rem;
}

.fiche-resum_img-crop {
  display: block;
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.fiche-resum_bottom {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($brand-second, .7);
  opacity: 0;
  transition: ease .6s;

  .btn-link {
    color: white;
    font-weight: 600;
    border: solid 1px white;
    border-radius: 3rem;
    position: relative;
    top: 2rem;
    transition: top ease .3s, opacity ease .3s;
  }
}

.fiche-resum:hover {

  .fiche-resum_bottom  {
    opacity: 1;
  }

  .btn-link {
    top: 0;
    opacity: 1;
  }
}

.favoris--resum {
  position: absolute;
  top: 2.5rem;
  right: 1rem;
}

.price-block {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: auto;
}

.price-label {
  display: inline-block;
  background-color: $brand-primary;
  padding: .8rem 1.2rem;
  color: white;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    display : inline-block;
    height : 0;
    width : 0;
    border-top : 15px solid transparent;
    border-bottom : 15px solid transparent;
    border-left : 10px solid $brand-primary;
  }
}

.span-label-price {
  display: inline-block;
  background-color: $brand-second;
  padding: .8rem 1.2rem .8rem 1.5rem;
  color: white;
  font-weight: bold;
  border-radius: 0 $border-radius-base 0 0;
}
