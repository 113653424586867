.text-shadow {
  text-shadow: 1px 1px 2px black;
}

.circle {
  clip-path: circle(50% at 50% 50%);
}

.border {
  border: 3px solid $brand-second;
  padding: 40px;
  border-radius: $border-radius-large;
}

.main-img-slider {
  // aspect-ratio: 4 / 3;
	height: 100%;
	max-height: 25rem;
  object-fit: contain;
}

.slick-slider {
	margin-bottom: 1rem;
}

.slide_img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}


li {
  list-style: none;
  padding: 10px;
}

.mt-05 {
  margin-top: .5rem;
}



.logos-partenaire {
	object-fit: scale-down;
	border-radius: $border-radius-base;
}

.partenaire-button {
  position: relative;
  padding-bottom: 40px;
  .btn {
    position: absolute;
    bottom: 0;
    left: 2rem;
    right: 2rem;
  }
}

.hero-index {

  @media(min-width: $screen-lg-min) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 50%;
    top: 20%;
    right: 10%;

  }

}

.card-dark {
  background-color: rgba(rgb(106, 106, 106), .2);
}

//table

.tableau33 {
  background-color: white;
  // border: 1px solid $gray-light;

  td {
        padding: 0.5rem;
        border: 0.1rem solid  $gray-light;
        color: black;
      }

}


.c-heroImageGrid {
 opacity: 0.8; 
}

//accueil 
.c-heroImageGrid {
	position: relative;
	overflow: hidden;
	height: 50vw;
	background: #fff;
	margin-bottom: 60px;
}

.c-heroImageGrid .container {
	display: flex;
	align-items: flex-end;
	position: absolute;
	top: -20vw;
	left: -8vw;
	transform: rotateZ(45deg);
	height: 100vw;
	width: 107vw;
}

.c-heroImageGrid .container .column {
	overflow: hidden;
	position: relative;
}

.c-heroImageGrid .container .column:nth-of-type(1) {
	border-right: 8px solid white;
	width: 25%;
	height: 66vw;
}

// .c-heroImageGrid .container .column:nth-of-type(1):hover {
// 	opacity: 0.75;
// 	// cursor: pointer;
// }

.c-heroImageGrid .container .column:nth-of-type(1):before {
	position: absolute;
	top: 7.8vw;
	left: 7.3vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	width: 37vw;
	height: 38vw;
	background: url(../images/charte/home1.jpg);
}


.c-heroImageGrid .container .column:nth-of-type(2) {
	display: flex;
	flex-direction: column-reverse;
	width: 75%;
	height: 109.1vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row {
	border-top: 8px solid #fff;
}


.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(1) {
	position: relative;
	overflow: hidden;
	height: 50vw;
}

// .c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(1):hover {
// 	opacity: 0.75;
// 	// cursor: pointer;
// }

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(1):before {
	position: absolute;
	top: -10.1vw;
	left: -14.3vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	width: 65vw;
	height: 39vw;
	background: url(../images/charte/home2.jpg);
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) {
	display: flex;
	height: 40.5vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(3) {
	border-top: none;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) {
	overflow: hidden;
	width: 50%;
	background-color: #8B8B8A;
  background: url(../images/charte/home5.jpg);
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text {
	width: 100%;
	height: 100%;
	overflow: visible;
	transform: rotateZ(-45deg);
	position: relative;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text h6 {
	position: absolute;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.35vw;
	width: 20vw;
	top: 16vw;
	left: 7vw;
	line-height: 1.55vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text p {
	text-align: right;
	position: absolute;
	color: #fff;
	font-size: 0.9vw;
	width: 18vw;
	top: 23.5vw;
	left: -3.5vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2) {
	position: relative;
	width: 70%;
	border-left: 8px solid white;
	overflow: hidden;
}

// .c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2):hover {
// 	opacity: 0.75;
// 	// cursor: pointer;
// }

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2):before {
	position: absolute;
	top: 0;
	left: -40vw;
	width: 110.1vw;
	height: 100vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	background: url(../images/charte/home4.jpg);
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(3) {
	position: relative;
	height: 18.6vw;
	overflow: hidden;
}

// .c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(3):hover {
// 	opacity: 0.75;
// 	// cursor: pointer;
// }

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(3):before {
	position: absolute;
	top: 7.5vw;
	left: 25vw;
	width: 33vw;
	height: 27vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	background: url(../images/charte/home3.jpg);
}
